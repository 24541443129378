@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  max-width: 1480px;
  margin: 0 auto;
  overflow: hidden;
  user-select: none;
}

.app {
  height: 100vh;
  width: 100%;
  background-position: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.wt-scroll::-webkit-scrollbar{
  width: 5px;
}

.wt-scroll::-webkit-scrollbar-thumb{
  background-color: rgb(186, 186, 186);
  border-radius: 20px;
}